<template>
  <div class="activity-organization-wrap">
      <div class="data-box">
          <div class="data-box-head">
            正在派餐中，请尽快前往派餐点领取
          </div>
          <div class="data-box-body">
              <div class="body-div">
                <div class="body-div-title">派餐信息</div>
                <div class="body-div-item" v-for="(item,index) in resultKeyValueList" :key="index">
                    <div class="span-key span-line">{{ item.key }}</div>
                    <div class="span-value" v-if="item.images">
                        {{ item.value }}
                    </div>
                    <div class="span-value" v-else>
                        {{item.value}}
                    </div>
                    <a v-if="item.phone" :href="'tel:' + item.phone" class="iconfont icon-dianhua1"></a>
                </div>

                <div class="body-div-item">
                    <div class="span-image" style="width: 100%;margin-left: 28%;">
                        <van-image class="image" fit="cover" round v-for="(item,index) in imgList" :key="index" :src="item"  @click="showImage(index,imgList)"/>
                    </div>
                </div>

              </div>
              <p class="bottom-tips">住工作顺利</p>
          </div>
      </div>
  </div>
</template>

<script>
import {ImagePreview} from "vant";
export default {
    data () {
        return {
            resultKeyValueList: [
                {key: "派餐餐点", value: "午餐"},
                {key: "派餐人员", value: "小黄人", phone: "16670199245"},
                {key: "派餐位置", value: "展会1号门口",images: []}
            ],
            imgList: [
                "https://hires.oss-cn-shenzhen.aliyuncs.com/1631191326952-822383-1623835330513.png",
                "https://hires.oss-cn-shenzhen.aliyuncs.com/1631591891540-43911-1555049364000.jpeg",
                "https://hires.oss-cn-shenzhen.aliyuncs.com/1631591895657-108224-1576482468000.jpeg",
                "https://hires.oss-cn-shenzhen.aliyuncs.com/1631591900380-205910-1631155366413.jpeg",
                "https://hires.oss-cn-shenzhen.aliyuncs.com/1631591905790-108224-1576482468000.jpeg",
                "https://hires.oss-cn-shenzhen.aliyuncs.com/1631591909659-108224-1576482468000.jpeg",
                "https://hires.oss-cn-shenzhen.aliyuncs.com/1631191333763-41139-1623835303741.png"
            ]
        }
    },
    methods: {
        showImage(index,array){
            ImagePreview({
                images: array,
                startPosition: index,
            });
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
</style>